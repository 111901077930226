<template>
  <div class="container">
  </div>
</template>

<script>

export default {
  name: "ArchiveRent",
  mounted() {
    this.$router.push({name: 'ArchiveRentApartments'});
  }
};
</script>

